//  Colour Palette
$dark-green: #1a6f5c;
$logo-green: #26901b;
$brighter-logo-green: #51bc58;
$darker-green: #1a4f43;

$blue: #244dd9;

$eggshell: #efefed;
$white: white;
$black: #000000;

$cream: #f2ebdc;
$error-red: #c80000;

// ? Palette Variables
$navbar: $dark-green;
$nav-links: rgb(239, 236, 236);
$mobile-nav-links: $darker-green;
$headings-colour: $dark-green;
$main-font-green: $darker-green;
$footer-text: $cream;

$green-solid-button: $dark-green;
$blue-button: $blue;

$button-text-light: $eggshell;
$button-text-green: $logo-green;

// =================================================

// Fonts
// @font-face variables
// Montserrat
$montserrat-regular-font-family: "Montserrat Regular", sans-serif;
$montserrat-italic-font-family: "Montserrat Italic", sans-serif;
$montserrat-thin-font-family: "Montserrat Thin", sans-serif;
$montserrat-thin-italic-font-family: "Montserrat Thin Italic", sans-serif;
$montserrat-extra-light-font-family: "Montserrat ExtraLight", sans-serif;
$montserrat-extra-light-italic-font-family: "Montserrat ExtraLight Italic",
  sans-serif;
$montserrat-light-font-family: "Montserrat Light", sans-serif;
$montserrat-light-italic-font-family: "Montserrat Light Italic", sans-serif;
$montserrat-medium-font-family: "Montserrat Medium", sans-serif;
$montserrat-medium-italic-font-family: "Montserrat Medium Italic", sans-serif;
$montserrat-semi-bold-font-family: "Montserrat SemiBold", sans-serif;
$montserrat-semi-bold-italic-font-family: "Montserrat SemiBold Italic",
  sans-serif;
$montserrat-bold-font-family: "Montserrat Bold", sans-serif;
$montserrat-bold-italic-font-family: "Montserrat Bold Italic", sans-serif;
$montserrat-extra-bold-font-family: "Montserrat ExtraBold", sans-serif;
$montserrat-extra-bold-italic-font-family: "Montserrat ExtraBold Italic",
  sans-serif;

// Roboto
$roboto-regular-font-family: "Roboto Regular", sans-serif;
$roboto-italic-font-family: "Roboto Italic", sans-serif;
$roboto-bold-font-family: "Roboto Bold", sans-serif;
$roboto-bold-italic-font-family: "Roboto Bold Italic", sans-serif;
$roboto-thin-font-family: "Roboto Thin", sans-serif;
$roboto-thin-italic-font-family: "Roboto Thin Italic", sans-serif;
$roboto-light-font-family: "Roboto Light", sans-serif;
$roboto-light-italic-font-family: "Roboto Light Italic", sans-serif;
$roboto-medium-font-family: "Roboto Medium", sans-serif;
$roboto-medium-italic-font-family: "Roboto Medium Italic", sans-serif;

// ? Font Family Variables
$main-font-font-family: $roboto-regular-font-family;
$headings-font: $montserrat-semi-bold-font-family;
$button-font: $montserrat-semi-bold-font-family;

// ? Font Size Variables
$headings-font-size: 3rem;
$paragraph-font-size-small: 1.6rem;
