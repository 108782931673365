@import "../../Styling/global.scss";

.dropdown-nav-container {
  position: absolute;
  top: 0;
  margin-top: 8rem;
  width: 100%;
  padding: 1rem 0 4rem 0;

  background-color: $white;
  overflow: hidden;

  z-index: 100000;

  // Box-shadow bottom only
  box-shadow: 0px 15px 20px -15px rgba(0, 0, 0, 0.4);
}

// .dropdown-fix {
//   position: fixed;

//   top: 0;
//   margin-top: 8rem;
//   width: 100%;
//   padding: 1rem 0 4rem 0;

//   background-color: $white;
//   overflow: hidden;

//   z-index: 10000;

//   // Box-shadow bottom only
//   box-shadow: 0px 15px 20px -15px rgba(0, 0, 0, 0.4);
// }

.nav-list {
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 0 3rem 0 3rem;

  li {
    padding: 1rem 0 1rem 1rem;
    // Background AND nav-links will change on hover
    &:active {
      background-color: lighten($eggshell, 3%);

      color: $logo-green;
      font-family: $montserrat-semi-bold-font-family;

      .covid-span {
        font-family: $montserrat-semi-bold-font-family;
      }

      .nav-links {
        color: $logo-green;
      }
    }
  }
}

@media (hover: hover) {
  .nav-list li:hover {
    background-color: lighten($eggshell, 3%);

    color: $logo-green;
    font-family: $montserrat-semi-bold-font-family;

    .covid-span {
      font-family: $montserrat-semi-bold-font-family;
    }

    .nav-links {
      color: $logo-green;
    }
  }
}

.nav-links {
  text-decoration: none;
  color: $mobile-nav-links;
  font-family: $montserrat-medium-font-family;
  font-size: 1.5rem;
  letter-spacing: 0.03rem;
}

.covid-container {
  display: flex;
  align-items: center;

  .nav-links {
    margin-left: 0.6rem;
  }

  .info-icon {
    margin-left: 0;
  }
}

.covid-span {
  margin-left: 0.5rem;
}

hr {
  width: 100%;
  height: 0.2rem;
  background-color: lighten($eggshell, 3%);
  border: none;
  border-radius: 1rem;
}

// Media Queries

// ? Large Tablet

// @include medium {
//   .dropdown-nav-container {
//     display: none;
//   }
// }

// ? Small Desktop

@include large {
  .dropdown-nav-container {
    display: none;
  }
}

// ? Medium Desktop

@include extra-large {
  .dropdown-nav-container {
    display: none;
  }
}

// ? Large Desktop

@include extra-extra-large {
  .dropdown-nav-container {
    display: none;
  }
}
