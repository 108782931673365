@import "../../Styling/global.scss";

.bkf-page-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  margin: 8rem 0 10rem 0;
}

.heading-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;

  margin-bottom: 3rem;
}

.heading {
  font-family: $headings-font;
  font-size: 4rem;
  color: $main-font-green;

  text-align: center;
  width: 100%;

  margin-bottom: 0.5rem;
}

.heading-underline {
  height: 0.4rem;
  width: 10rem;
  background-color: $dark-green;

  border-radius: 5rem;
}

.text {
  font-family: $main-font-font-family;
  font-size: 1.6rem;
  line-height: 2.5rem;
  color: $main-font-green;

  text-align: center;

  width: 80%;

  margin-top: 1.5em;
}

.email-link {
  color: $blue;
  &:active {
    color: $logo-green;
    font-family: $roboto-bold-font-family;
  }
}

@media (hover: hover) {
  .email-link:hover {
    color: $logo-green;

    font-family: $roboto-bold-font-family;
  }
}

.gallery-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  width: 100vw;

  //   background-color: lighten($dark-green, 5%);

  background-image: url("../Berufskraftfahrerqualifikation/Static/bg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  margin: 5rem 0 5rem 0;
  padding: 10rem 0 10rem 0;
}

.img {
  width: 30rem;
  height: 30rem;

  margin: 3.5rem;

  object-fit: cover;

  //   border-radius: 0.5rem;

  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

// @media (hover: hover) {
//   .img:hover {
//     transition: ease-in-out 0.2s;
//     width: 50rem;
//     height: auto;
//   }
// }

@include extra-extra-small {
  .heading {
    font-size: 2.5rem;
  }
}

@include x-small {
  .heading {
    font-size: 3rem;
  }
}
