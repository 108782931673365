@import "../../Styling/global.scss";

.contact-form-section {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100vw;
  height: auto;
  padding: 10rem 0 10rem 0;
  margin: 10rem 0 0 0;
  background-color: $dark-green;
}

.form-heading {
  // margin: 1rem 0 3rem 0;

  align-self: center;

  font-size: 2.3rem;
  text-align: center;

  color: $main-font-green;
  font-family: $headings-font;
}

.heading-underline {
  align-self: center;

  height: 0.4rem;
  width: 10rem;
  background-color: $dark-green;
  border-radius: 5rem;

  margin-top: 1rem;
}

.contact-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  height: auto;

  // Same settings as map and text container in kontakt.module.scss
  // margin: 3rem;
  width: 30rem;

  background-color: white;
  // padding: 5rem 5rem 2rem 5rem;

  border-radius: 0.5rem;
  box-shadow: rgba(26, 79, 67, 0.24) 0px 3px 8px;
}

.contact-form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  width: 100%;

  // margin: 3rem;
  font-family: $main-font-font-family;
  color: $main-font-green;
}

.contact-form-label {
  margin: 0 0 1rem 2rem;
  font-size: 1.6rem;
}

.contact-form-input {
  margin: 0 0 2rem 2rem;

  outline: none;
  border: solid 2px $logo-green;
  border-top: none;
  border-right: none;
  border-left: none;

  padding: 1rem 0.5rem 1rem 0.5rem;
  font-size: 1.6rem;

  background-color: lighten($eggshell, 5%);

  &::placeholder {
    font-family: $roboto-light-italic-font-family;
    letter-spacing: 0.1rem;
  }

  &:active {
    .contact-form-input:hover {
      border-bottom: solid 2px $brighter-logo-green;
      // border-radius: 0.3rem;
      background-color: lighten($eggshell, 3%);
    }
  }

  // Disable from on successful submit
  &:disabled {
    background-color: $eggshell;
    border: none;
    &::placeholder {
      color: $white;
    }
    &:hover,
    &:active {
      background-color: $eggshell;
      border: none;
    }
  }
}

@media (hover: hover) {
  .contact-form-input:hover {
    border-bottom: solid 2px $brighter-logo-green;
    border-radius: 0.3rem;
    background-color: lighten($eggshell, 3%);
    font-size: 1.6rem;
  }
}

.error-message {
  color: $error-red;
  font-family: $main-font-font-family;
  font-size: 1.3rem;
  margin: 0 0 1rem 2rem;
}

.sent-message-notification {
  color: $logo-green;
  font-family: $main-font-font-family;
  font-size: 2.8rem;
  text-align: center;

  width: 100%;
  align-self: center;

  margin-top: 3rem;

  span {
    font-size: 2.3rem;
  }
}

.text-container {
  resize: none;
  height: 10rem;
  width: 85%;
}

.submit-button {
  align-self: center;
  @include gradient-button-dark;

  margin: 2.5rem 0 0 0;
}

// ? Media Queries

@include x-small {
  .contact-form-container {
    height: auto;

    padding: 6rem 3rem 6rem 3rem;
  }

  .heading-underline {
    margin-bottom: 4rem;
  }

  .contact-form-input {
    width: 85%;
  }

  .submit-button {
    margin-top: 3rem;
  }

  .sent-message-notification {
    font-size: 2rem;

    span {
      font-size: 1.7rem;
    }
  }
}

@include small {
  .contact-form-container {
    width: 40rem;
    height: auto;

    padding: 8rem 3rem 8rem 3rem;
  }

  .heading-underline {
    margin-bottom: 5rem;
  }

  .contact-form-input {
    width: 85%;
  }

  .submit-button {
    margin-top: 5rem;
  }
  .sent-message-notification {
    font-size: 2.5rem;

    span {
      font-size: 2rem;
    }
  }
}

@include medium {
  .contact-form-container {
    width: 60rem;
    height: auto;

    padding: 8rem 5rem 8rem 5rem;
  }

  .heading-underline {
    margin-bottom: 5rem;
  }

  .contact-form-input {
    width: 91%;
  }

  .submit-button {
    margin-top: 5rem;
  }
}

@include large {
  .contact-form-container {
    width: 60rem;
    height: auto;

    padding: 10rem 5rem 7rem 5rem;
  }

  .heading-underline {
    margin-bottom: 5rem;
  }

  .contact-form-input {
    width: 91%;
  }

  .submit-button {
    margin-top: 5rem;
  }
}

@include extra-large {
  .contact-form-container {
    width: 60rem;
    height: auto;

    padding: 10rem 5rem 8rem 5rem;
  }

  .heading-underline {
    margin-bottom: 5rem;
  }

  .contact-form-input {
    width: 91%;
  }

  .submit-button {
    margin-top: 5rem;
  }
}

@include extra-extra-large {
  .contact-form-container {
    width: 60rem;
    height: auto;

    padding: 10rem 5rem 8rem 5rem;
  }

  .heading-underline {
    margin-bottom: 5rem;
  }

  .contact-form-input {
    width: 91%;
  }

  .submit-button {
    margin-top: 5rem;
  }
}

@include extra-extra-small {
  .contact-form-container {
    width: 20rem;
    height: auto;
  }

  .contact-form-input {
    width: 83%;
  }
}
