*,
*::before,
*::after {
  margin: 0;
  padding: 0;
}

:root {
  font-size: 62.5%;
}

body {
  box-sizing: border-box;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
