/* #### Generated By: http://www.cufonfonts.com #### */

// Montserrat

@font-face {
  font-family: "Montserrat Regular";
  font-style: normal;
  font-weight: normal;
  src: local("Montserrat Regular"),
    url("../Fonts/montserrat-cufonfonts-webfont/Montserrat-Regular.woff")
      format("woff");
}

@font-face {
  font-family: "Montserrat Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Montserrat Italic"),
    url("../Fonts/montserrat-cufonfonts-webfont/Montserrat-Italic.woff")
      format("woff");
}

@font-face {
  font-family: "Montserrat Thin";
  font-style: normal;
  font-weight: normal;
  src: local("Montserrat Thin"),
    url("../Fonts/montserrat-cufonfonts-webfont/Montserrat-Thin.woff")
      format("woff");
}

@font-face {
  font-family: "Montserrat Thin Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Montserrat Thin Italic"),
    url("../Fonts/montserrat-cufonfonts-webfont/Montserrat-ThinItalic.woff")
      format("woff");
}

@font-face {
  font-family: "Montserrat ExtraLight";
  font-style: normal;
  font-weight: normal;
  src: local("Montserrat ExtraLight"),
    url("../Fonts/montserrat-cufonfonts-webfont/Montserrat-ExtraLight.woff")
      format("woff");
}

@font-face {
  font-family: "Montserrat ExtraLight Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Montserrat ExtraLight Italic"),
    url("../Fonts/montserrat-cufonfonts-webfont/Montserrat-ExtraLightItalic.woff")
      format("woff");
}

@font-face {
  font-family: "Montserrat Light";
  font-style: normal;
  font-weight: normal;
  src: local("Montserrat Light"),
    url("../Fonts/montserrat-cufonfonts-webfont/Montserrat-Light.woff")
      format("woff");
}

@font-face {
  font-family: "Montserrat Light Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Montserrat Light Italic"),
    url("../Fonts/montserrat-cufonfonts-webfont/Montserrat-LightItalic.woff")
      format("woff");
}

@font-face {
  font-family: "Montserrat Medium";
  font-style: normal;
  font-weight: normal;
  src: local("Montserrat Medium"),
    url("../Fonts/montserrat-cufonfonts-webfont/Montserrat-Medium.woff")
      format("woff");
}

@font-face {
  font-family: "Montserrat Medium Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Montserrat Medium Italic"),
    url("../Fonts/montserrat-cufonfonts-webfont/Montserrat-MediumItalic.woff")
      format("woff");
}

@font-face {
  font-family: "Montserrat SemiBold";
  font-style: normal;
  font-weight: normal;
  src: local("Montserrat SemiBold"),
    url("../Fonts/montserrat-cufonfonts-webfont/Montserrat-SemiBold.woff")
      format("woff");
}

@font-face {
  font-family: "Montserrat SemiBold Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Montserrat SemiBold Italic"),
    url("../Fonts/montserrat-cufonfonts-webfont/Montserrat-SemiBoldItalic.woff")
      format("woff");
}

@font-face {
  font-family: "Montserrat Bold";
  font-style: normal;
  font-weight: normal;
  src: local("Montserrat Bold"),
    url("../Fonts/montserrat-cufonfonts-webfont/Montserrat-Bold.woff")
      format("woff");
}

@font-face {
  font-family: "Montserrat Bold Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Montserrat Bold Italic"),
    url("../Fonts/montserrat-cufonfonts-webfont/Montserrat-BoldItalic.woff")
      format("woff");
}

@font-face {
  font-family: "Montserrat ExtraBold";
  font-style: normal;
  font-weight: normal;
  src: local("Montserrat ExtraBold"),
    url("../Fonts/montserrat-cufonfonts-webfont/Montserrat-ExtraBold.woff")
      format("woff");
}

@font-face {
  font-family: "Montserrat ExtraBold Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Montserrat ExtraBold Italic"),
    url("../Fonts/montserrat-cufonfonts-webfont/Montserrat-ExtraBoldItalic.woff")
      format("woff");
}

// Roboto

@font-face {
  font-family: "Roboto Regular";
  font-style: normal;
  font-weight: normal;
  src: local("Roboto Regular"),
    url("../Fonts/roboto-cufonfonts-webfont/Roboto-Regular.woff") format("woff");
}

@font-face {
  font-family: "Roboto Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Roboto Italic"),
    url("../Fonts/roboto-cufonfonts-webfont/Roboto-Italic.woff") format("woff");
}

@font-face {
  font-family: "Roboto Bold";
  font-style: normal;
  font-weight: normal;
  src: local("Roboto Bold"),
    url("../Fonts/roboto-cufonfonts-webfont/Roboto-Bold.woff") format("woff");
}

@font-face {
  font-family: "Roboto Bold Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Roboto Bold Italic"),
    url("../Fonts/roboto-cufonfonts-webfont/Roboto-BoldItalic.woff")
      format("woff");
}

@font-face {
  font-family: "Roboto Thin";
  font-style: normal;
  font-weight: normal;
  src: local("Roboto Thin"),
    url("../Fonts/roboto-cufonfonts-webfont/Roboto-Thin.woff") format("woff");
}

@font-face {
  font-family: "Roboto Thin Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Roboto Thin Italic"),
    url("../Fonts/roboto-cufonfonts-webfont/Roboto-ThinItalic.woff")
      format("woff");
}

@font-face {
  font-family: "Roboto Light";
  font-style: normal;
  font-weight: normal;
  src: local("Roboto Light"),
    url("../Fonts/roboto-cufonfonts-webfont/Roboto-Light.woff") format("woff");
}

@font-face {
  font-family: "Roboto Light Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Roboto Light Italic"),
    url("../Fonts/roboto-cufonfonts-webfont/Roboto-LightItalic.woff")
      format("woff");
}

@font-face {
  font-family: "Roboto Medium";
  font-style: normal;
  font-weight: normal;
  src: local("Roboto Medium"),
    url("../Fonts/roboto-cufonfonts-webfont/Roboto-Medium.woff") format("woff");
}

@font-face {
  font-family: "Roboto Medium Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Roboto Medium Italic"),
    url("../Fonts/roboto-cufonfonts-webfont/Roboto-MediumItalic.woff")
      format("woff");
}
