@import "../../Styling/global.scss";

.dsgvo-page-container {
  width: 100vw;

  display: flex;
  justify-content: center;

  font-family: $roboto-light-font-family;

  margin-bottom: 20rem;
}

.content-container {
  width: 80%;

  max-width: 120rem;
  padding: 5rem;
}

.main-headings {
  font-size: 2.5rem;
  letter-spacing: 0.1rem;
  margin-bottom: 2rem;

  margin-top: 2rem;
}

.sub-headings {
  font-size: 2rem;
  margin: 0 0 2rem 0;
}

.secondary-sub-headings {
  font-size: 1.8rem;
  margin: 0 0 2rem 0;
}

.spans,
.paragraphs,
.list,
.nested-list {
  font-size: 1.3rem;
  line-height: 2.5rem;

  margin-bottom: 2rem;
}

.spans {
  display: block;
}

.anchor-tags {
  text-decoration: none;
  color: $dark-green;

  &:active {
    text-decoration: underline;
    color: $logo-green;
    font-family: $roboto-bold-font-family;
  }
}

@media (hover: hover) {
  .anchor-tags:hover {
    text-decoration: underline;
    color: $logo-green;
    font-family: $roboto-bold-font-family;
  }
}

.list {
  padding-left: 4rem;
}
