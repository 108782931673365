@import "../../Styling/global.scss";

// ? Full page

.home-page-container {
  position: relative;
  width: 100vw;
}

.headings {
  color: $headings-colour;
  font-family: $headings-font;
  font-size: $headings-font-size;

  text-align: center;

  hyphens: auto;
  word-wrap: break-word;

  padding: 0 2rem 0 2rem;
}

// ? Landing View

.landing-view-container {
  position: relative;

  display: flex;
  flex-direction: column;

  overflow: hidden;
  width: 100vw;

  // To match .slideshow image height - across all media queries to make sure there is no gap with whitespace
  height: 90vh;
}

.heading-content-container {
  position: absolute;
  // top: 10rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100vw;
  height: 80vh;

  // height: 20vh;

  // margin: 2rem 0 2rem 0;

  padding-bottom: 2rem;

  // background-color: $white;

  z-index: 9005 !important;

  h2 {
    // position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    max-width: 70rem;
    // height: 50%;

    margin-bottom: 3rem;

    background-image: radial-gradient(
      ellipse 50% 35% at 50% 50%,
      $darker-green,
      transparent
    );
    // transform: translate(-50%, -50%);
    letter-spacing: 2;
    // left: 50%;
    // top: 30%;
    font-family: $montserrat-bold-font-family;
    text-transform: uppercase;
    text-align: center;

    font-size: 3rem;
    color: #ffe6ff;
    // text-shadow: 0 0 0.6rem #ffe6ff, 0 0 1.5rem $brighter-logo-green,
    //   -0.2rem 0.1rem 1rem $brighter-logo-green,
    //   0.2rem 0.1rem 1rem $brighter-logo-green, 0 -0.5rem 2rem $logo-green,
    //   0 0.5rem 3rem $logo-green;

    // ! Test to see if it seems less blurry
    text-shadow: 0 0 0.1rem #ffe6ff, 0 0 1.5rem $brighter-logo-green,
      -0.2rem 0.1rem 1rem $brighter-logo-green,
      0.2rem 0.1rem 1rem $brighter-logo-green, 0 -0.5rem 2rem $logo-green,
      0 0.5rem 3rem $logo-green;
  }
}

.button-container {
  display: flex;
  justify-content: center;

  width: 80%;
}

.more-about-button {
  @include gradient-button-cream;

  text-decoration: none;
  width: fit-content;
  padding: 1rem 2rem 1rem 2rem;
}

// ? About Section

.about-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: auto;
  background-color: $eggshell;

  padding: 10rem 0 15rem 0;
}

.about-content-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-top: 3rem;
}

.about-text-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 80%;
  padding: 1rem 0 4rem 0;
}

.about-paragraph {
  color: $main-font-green;
  font-family: $main-font-font-family;

  font-size: $paragraph-font-size-small;

  max-width: 35rem;

  text-align: center;
  line-height: 2.5rem;

  padding: 1.5rem 0 1rem 0;

  &:nth-of-type(1) {
    margin-top: 3rem;
  }

  &:nth-last-of-type(1) {
    margin-bottom: 4rem;
  }
}

.gradient-button-dark {
  @include gradient-button-dark;
}

.team-photo {
  width: 60%;
  max-width: 65rem;

  object-fit: contain;
  border-radius: 0.8rem;
  margin-top: 2.5rem;
}

// ? Extra Info Section

.extra-info-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: auto;

  padding: 10rem 0 10rem 0;
}

.extra-info-heading {
  width: 80%;
  max-width: 60rem;

  margin-top: 3rem;
}

.extra-info-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;

  margin: 6rem 0 2rem 0;
}

.info-boxes {
  display: grid;
  grid-template-rows: 11rem 9rem;

  width: 20rem;
  height: 20rem;

  margin: 2rem;
}

.info-icon {
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 7rem;
  }
}

.info-text {
  display: flex;
  justify-content: center;
  text-align: center;

  h4 {
    font-family: $headings-font;
    font-size: 1.4rem;
  }
}

.gradient-button-light {
  @include gradient-button-light;
}

.dividing-line {
  background-color: lighten($dark-green, 5%);
  height: 0.25rem;
  width: 80%;

  margin: 15rem 0 0 0;
}

// ? Berufskraftfahrerqualifikation Section

.berufs-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: auto;

  padding: 0 0 10rem 0;
  margin-top: -2rem;
}

.berufs-background-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background-color: $brighter-logo-green;
  width: 100%;
  height: auto;

  padding: 10rem 0 10rem 0;
  margin: 11rem 0 0 0;
}

.berufs-icons-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  width: 80%;
  max-width: 80rem;

  img {
    width: 10rem;
    padding: 2rem;
  }
}

.berufs-text {
  font-family: $montserrat-semi-bold-font-family;
  color: $white;
  font-size: 2rem;
  line-height: 2.5rem;

  text-align: center;

  width: 80%;
  max-width: 80rem;

  margin: 6rem 0 0 0;
}

.berufs-button {
  @include transparent-button;

  margin-top: 6rem;
}

// ? News Section

.news-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  height: auto;
  width: 100%;

  margin: 15rem 0 14rem 0;
}

.news-content {
  margin-top: 3rem;

  width: 80%;
  max-width: 80rem;

  text-align: center;
  color: $main-font-green;
  font-family: $main-font-font-family;

  font-size: $paragraph-font-size-small;
}

.news-paragraphs {
  line-height: 3rem;

  padding: 1.7rem 0 4rem 0;

  font-family: $montserrat-bold-font-family;

  // font-size: 2rem;
}

.covid-heading {
  margin: 5rem 0 3rem 0;
}

.email-link {
  color: $blue;
}

.news-contact-button-container {
  margin: 2rem 0 2rem 0;
}

.news-contact-button {
  @include gradient-button-dark;
  text-decoration: none;
}

@media (hover: hover) {
  .email-link:hover {
    color: $logo-green;
  }
}

// ? Contact Section

.contact-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  height: auto;
  width: 100%;

  margin: 9rem 0 14rem 0;
}

.contact-button {
  @include gradient-button-dark;

  margin-top: 6rem;
}

// ? ALL Media queries

@include x-small {
  .berufs-icons-container {
    img {
      width: 7rem;
      padding: 2rem;
    }
  }

  .berufs-text {
    font-size: 1.6rem;

    margin-top: 4rem;
  }

  .berufs-button {
    margin-top: 5rem;
  }
}

@include small {
  .heading-content-container {
    h2 {
      font-size: 3.5rem;
      line-height: 4.5rem;
    }
  }

  .berufs-icons-container {
    img {
      width: 7rem;
      padding: 2rem;
    }
  }

  .berufs-text {
    font-size: 1.6rem;

    margin-top: 4rem;
  }

  .berufs-button {
    margin-top: 5rem;
  }

  .contact-section {
    margin: 11rem 0 16rem 0;
  }
}

@include medium {
  .heading-content-container {
    h2 {
      font-size: 4.5rem;
    }
  }

  .about-paragraph {
    max-width: 45rem;
  }

  .team-photo {
    width: 70%;
    margin-top: 2rem;
  }

  .contact-section {
    margin: 13rem 0 18rem 0;
  }
}

@include large {
  .heading-content-container {
    h2 {
      font-size: 4.5rem;
    }
  }

  .about-paragraph {
    max-width: 55rem;
  }

  .team-photo {
    width: 80%;
    margin-top: 2rem;
  }

  .contact-section {
    margin: 15rem 0 20rem 0;
  }
}

@include extra-large {
  .landing-view-container {
    height: 85vh;
  }

  .heading-content-container {
    h2 {
      font-size: 5.5rem;
    }
  }

  .about-paragraph {
    max-width: 85rem;
  }

  .team-photo {
    width: 90%;
    margin-top: 2rem;
  }

  .contact-section {
    margin: 15rem 0 20rem 0;
  }
}

@include extra-extra-large {
  .landing-view-container {
    height: 88vh;
  }

  .heading-content-container {
    h2 {
      font-size: 5.5rem;
    }
  }

  .about-paragraph {
    max-width: 95rem;
  }

  .about-section {
    padding: 15rem 0 20rem 0;
  }

  .team-photo {
    width: 100%;
    margin-top: 2rem;
  }

  .contact-section {
    margin: 15rem 0 20rem 0;
  }
}
