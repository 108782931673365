@import "../../Styling/global.scss";

.slideshow-image {
  height: 90vh;
  width: 100vw;

  overflow: hidden;

  object-fit: cover;

  // margin-bottom: 2.2rem;
}

// @include small {
//   .slideshow-image {
//     height: 90vh;
//   }
// }

// @include medium {
//   .slideshow-image {
//     height: 95vh;
//   }
// }

// @include large {
//   .slideshow-image {
//     height: 88vh;
//   }
// }

@include extra-large {
  .slideshow-image {
    height: 85vh;
  }
}

@include extra-extra-large {
  .slideshow-image {
    height: 88vh;
  }
}
