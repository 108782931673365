@import "../../Styling/global.scss";

// ? Dots

.slick-dots {
  bottom: 5rem !important;
  background-color: transparent !important;
  z-index: 80000 !important;

  li {
    margin: 0 1.5rem 0 1.5rem !important;

    button {
      &:hover,
      &:focus {
        outline: none !important;
        &:before {
          opacity: 1 !important;
        }
      }

      &:before {
        position: absolute !important;
        top: 0 !important;
        left: 0 !important;
        // content: $slick-dot-character;
        width: 20px !important;
        height: 20px !important;
        // font-family: $slick-font-family;
        // font-size: 2rem !important;

        // line-height: 20px;
        text-align: center !important;
        color: grey !important;
        opacity: 0.8 !important;
        -webkit-font-smoothing: antialiased !important;
        -moz-osx-font-smoothing: grayscale !important;
      }
    }

    &.slick-active button:before {
      color: $logo-green !important;
      opacity: 1 !important;
    }
  }
}

// ? Arrows

.slick-prev:before,
.slick-next:before {
  color: $logo-green !important;
  // font-size: 2.5rem !important;
}

@include x-small {
  li {
    button {
      &:before {
        font-size: 1.2rem !important;
      }
    }
  }
}

@include small {
  li {
    button {
      &:before {
        font-size: 1.6rem !important;
      }
    }
  }
}

@include medium {
  li {
    button {
      &:before {
        font-size: 1.6rem !important;
      }
    }
  }
}

@include large {
  li {
    button {
      &:before {
        font-size: 1.8rem !important;
      }
    }
  }
}

@include extra-large {
  li {
    button {
      &:before {
        font-size: 1.8rem !important;
      }
    }
  }
}

@include extra-extra-large {
  li {
    button {
      &:before {
        font-size: 1.8rem !important;
      }
    }
  }
}
